import React, {useState} from "react";
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";

import logo from '../assets/img/logo.png';
import '../assets/dependencies';
import RegisterForm from "../forms/register";

const Registration = () => {

    const initialValues = {
        username: "",
        password: "",
    };

    const validationSchema = Yup.object().shape({
        username: Yup.string().min(3).max(15).required(),
        password: Yup.string().min(4).max(20).required(),
    });


    return (
        <div></div>
/*
        <div className="gray-bg">
            <div className={"middle-box text-center loginscreen   animated fadeInDown"}>
                <div>
                    <div>

                        <img alt="" className="logo-app" src={logo}/>

                    </div>
                    <h3> SearchAdmin</h3>
                    <p>Create account to see it in action.</p>
                    <RegisterForm/>
                    <p className="m-t"><small>Inspinia we app framework base on Bootstrap 3 &copy; 2014</small></p>
                </div>
            </div>
        </div>

 */
    );
}

export default Registration;
