import React, {Component} from "react";

class InputBox extends Component {
    state = {
        text: "",
        name: ""
    };

    constructor(props) {
        super(props);
        this.state.text = props.text;
        this.state.name = props.name;
    }

    onChangeEvent = (event) => {
        //this.props.onChangeHandler(this.state.name, event.target.value);
        //this.setState({text: event.target.value});
    }

    render() {
        return (
            <input
                type="text"
                className="form-control"
                value={this.state.text}
                onChange={(e) => {
                    //setIsVolunteer(e.target.checked);
                    this.setState({text: e.target.value});
                    this.props.onChangeHandler(this.state.name, e.target.value);
                }}

            />
        );
    }
}

export default InputBox;