import React, {useState, useContext} from 'react'
import {useHistory} from 'react-router-dom'
import axios from "../axiosConfig.js";
import AuthContext from '../store/auth-context';
const LoginForm = () => {
    const history = useHistory();
    const [userName, SetUserName] = useState('');
    const [userPassword, SetUserPassword] = useState('');
    const [error, setError] = useState(false);

    const authCtx = useContext(AuthContext);
    const onSubmitHandler = (event) => {

        debugger;
        event.preventDefault();
        if (userName.length === 0 || userPassword.length === 0) {
            setError(true);
        } else {
            const data = {
                userName: userName,
                password: userPassword,
            };

            axios.post("/admin/login", data).then((response) => {
                if (response.data.error) {
                    alert(response.data.error)
                } else {

                    if (userName === 'nikhil') {
                        authCtx.setAdmin(true);
                    }
                    else if (userName === 'admin') {
                        authCtx.setAdmin(true);
                    } else {
                        authCtx.setAdmin(false);
                    }
                    authCtx.login(response.data);
                    history.replace('/Home');
                }

            });

        }
    };

    const nameChangeHandler = (event) => {
        SetUserName(event.target.value);
    };

    const passwordChangeHandler = (event) => {
        SetUserPassword(event.target.value);
    };
    return <div>
        <form className="m-t" onSubmit={onSubmitHandler}>
            <div className="form-group">
                <input type="text" className="form-control" placeholder="Username" required=""
                       onChange={nameChangeHandler}/>
            </div>
            {error && userName.length <= 0 ?
                <div>
                    <label style={{color: "red"}}>UserName can't be Empty!</label>
                </div> : ""}
            <div className="form-group">
                <input type="password" className="form-control" placeholder="Password" required=""
                       onChange={passwordChangeHandler}/>
            </div>
            {error && userPassword.length <= 0 ?
                <div>
                    <label style={{color: "red"}}>Password can't be Empty!</label>
                </div> : ""}
            <button type="submit" className={"btn btn-primary block full-width m-b"}>Login</button>
            <div style={{height: "200px"}}>...</div>
            {/* <a href="/registration"><small>Forgot password?</small></a> */}
            {/* <p className={"text-muted text-center"}><small>Do not have an account?</small></p>
    <a className={"btn btn-sm btn-white btn-block"} href="/registration">Create an account</a> */}
        </form>
    </div>
};
export default LoginForm