import React from "react";
import {Link} from 'react-router-dom';
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import axios from "axios";
import {useHistory, BrowserRouter as Router, Switch, Route} from "react-router-dom";
import logo from '../assets/img/logo.png';
import Navigation from '../helpers/navigations'
import Header from '../helpers/header'
import Ibox from '../helpers/ibox'

function CreatePost() {
    let history = useHistory();


    return (
        <div id="wrapper" className="Dashboard_1">
            <Navigation/>
            <div id="page-wrapper" className="gray-bg">
                <Header/>
                <div className="wrapper wrapper-content">
                    <Ibox/>
                    <Switch>
                    </Switch>

                </div>
            </div>
        </div>
    );
}

export default CreatePost;
