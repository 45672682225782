import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import DatePicker from 'react-datepicker';
import axios from "../axiosConfig.js";
import {Button} from "react-bootstrap";

const Ibox = () => {
    const [Duration, setDuration] = useState({filter: 'daily'});
    const [visitClicks, setVisitClicks] = useState(0);
    const [registerdUsers, setRegisterdUsers] = useState(0);
    const [newToilets, setNewToilets] = useState(0);
    const [newReviews, setNewReviews] = useState(0);
    const [newIssues, setNewIssues] = useState(0);
    const [activeVolunteers, setActiveVolunteers] = useState(0);
    const [requestedVolunteers, setRequestedVolunteers] = useState(0);
    const [claimedPlaces, setclaimedPlaces] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    useEffect(() => {
            dashboardDetailHandler();
        },
        []);
    const dashboardDetailHandler = (event) => {

        if (event != undefined) {
            Duration.filter = event.target.value;
        }

        axios.post(`/home/GetReports`, {

                duration: Duration.filter,
                startDate: startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate(),
                endDate: endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate(),
            },
            {
                headers: {
                    accessToken: localStorage.token
                },
            },
        ).then((response) => {

            setVisitClicks(response.data[0].TotalVisitClicks);
            setRegisterdUsers(response.data[0].TotalRegisterdUsers);
            setNewToilets(response.data[0].TotalNewToilets);
            setNewReviews(response.data[0].TotalNewReviews);
            setNewIssues(response.data[0].TotalNewIssues);
            setActiveVolunteers(response.data[0].TotalActiveVolunteers);
            setRequestedVolunteers(response.data[0].TotalRequestedVolunteers);
            setclaimedPlaces(response.data[0].TotalclaimedPlaces);


        });
    }
    return (
        <div>
            <div className="row">
                <div className="col-sm-3 m-b-xs">
                    <div data-toggle="buttons" className="btn-group btn-group-toggle">
                        <label className="btn btn-sm btn-white active"> <input type="radio" id="daily" value="Daily"
                                                                               name="options"
                                                                               onClick={dashboardDetailHandler}/> Day
                        </label>
                        <label className="btn btn-sm btn-white"> <input type="radio" id="weekely" name="options"
                                                                        value="Weekely"
                                                                        onClick={dashboardDetailHandler}/> Week </label>
                        <label className="btn btn-sm btn-white"> <input type="radio" id="monthly" name="options"
                                                                        value="Monthly"
                                                                        onClick={dashboardDetailHandler}/> Month
                        </label>
                        <label className="btn btn-sm btn-white"> <input type="radio" id="monthly" name="options"
                                                                        value="Yearly"
                                                                        onClick={dashboardDetailHandler}/> Yearly
                        </label>
                    </div>

                </div>
                <div className="col-sm-4 m-b-xs">
                    <div className="btn-group btn-group-toggle">

                        <DatePicker
                            className="btn btn-md btn-white"
                            dateFormat="MM/dd/yyyy"
                            selected={startDate}
                            placeholderText="Start Date"
                            showPopperArrow={false}
                            format="MM-dd-y"
                            onChange={(e) => setStartDate(e)}
                            isClearable
                        />

                        <DatePicker
                            className="btn btn-md btn-white"
                            dateFormat="MM/dd/yyyy"
                            selected={endDate}
                            placeholderText="End Date"
                            showTimeSelect={false}
                            onChange={date => setEndDate(date)}
                            isClearable
                        />


                    </div>


                </div>
                <div className="col-sm-3 m-b-xs">
                    <Button variant="primary" size="sm" value="dateRange" onClick={dashboardDetailHandler}>
                        Submit
                    </Button>{' '}
                </div>
                {/* <div className="col-sm-3">
                    <div className="input-group">
                        <input type="text" placeholder="Search" className="form-control-sm form-control"/> <span className="input-group-btn">
                            <button type="button" className="btn btn-sm btn-primary"> Go!</button>
                        </span>
                    </div>
                </div> */}
            </div>
            <div className="row">

                <div className="col-lg-4">
                    <div className="ibox ">
                        <div className="ibox-title">
                            <div className="ibox-tools">
                                <span className="label label-primary float-right">{Duration.filter}</span>
                            </div>
                            <h5>Vistit Clicks</h5>
                        </div>
                        <div className="ibox-content">
                            <h1 className="no-margins">{visitClicks}</h1>


                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="ibox ">
                        <div className="ibox-title">
                            <div className="ibox-tools">
                                <span className="label label-primary float-right">{Duration.filter}</span>
                            </div>
                            <h5>Registered Users</h5>
                        </div>
                        <div className="ibox-content">
                            <h1 className="no-margins">{registerdUsers}</h1>

                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="ibox ">
                        <div className="ibox-title">
                            <div className="ibox-tools">
                                <span className="label label-primary float-right">{Duration.filter}</span>
                            </div>
                            <h5>New Toilets</h5>
                        </div>
                        <div className="ibox-content">
                            <h1 className="no-margins">{newToilets}</h1>

                        </div>
                    </div>
                </div>
            </div>
            <div className="row">

                <div className="col-lg-4">
                    <div className="ibox ">
                        <div className="ibox-title">
                            <div className="ibox-tools">
                                <span className="label label-primary float-right">{Duration.filter}</span>
                            </div>
                            <h5>Reviews</h5>
                        </div>
                        <div className="ibox-content">
                            <h1 className="no-margins">{newReviews}</h1>

                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="ibox ">
                        <div className="ibox-title">
                            <div className="ibox-tools">
                                <span className="label label-primary float-right">{Duration.filter}</span>
                            </div>
                            <h5>New Issues</h5>
                        </div>
                        <div className="ibox-content">
                            <h1 className="no-margins">{newIssues}</h1>

                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="ibox ">
                        <div className="ibox-title">
                            <div className="ibox-tools">
                                <span className="label label-primary float-right">{Duration.filter}</span>
                            </div>
                            <h5> Requested Volunteers</h5>
                        </div>
                        <div className="ibox-content">
                            <h1 className="no-margins">{activeVolunteers}</h1>

                        </div>
                    </div>
                </div>
            </div>
            <div className="row">

                <div className="col-lg-4">
                    <div className="ibox ">
                        <div className="ibox-title">
                            <div className="ibox-tools">
                                <span className="label label-primary float-right">{Duration.filter}</span>
                            </div>
                            <h5>Active Volunteers</h5>
                        </div>
                        <div className="ibox-content">
                            <h1 className="no-margins">{requestedVolunteers}</h1>

                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="ibox ">
                        <div className="ibox-title">
                            <div className="ibox-tools">
                                <span className="label label-primary float-right">{Duration.filter}</span>
                            </div>
                            <h5>Claimed Places</h5>
                        </div>
                        <div className="ibox-content">
                            <h1 className="no-margins">{claimedPlaces}</h1>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

};

export default Ibox;