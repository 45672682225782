import React, {useEffect, useState} from "react";
import {Modal, OverlayTrigger, Tooltip, CloseButton} from "react-bootstrap";
import Edit from "../common/edit";

const Table = (props) => {
    let table_header = props.table_data[0];
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({});

    const editHandler = (object) => {
        setModalData(object);
        setShowModal(true);
    };

    function handleCloseModal() {

        setShowModal(false);
        props.refreshDataHandler();
    }

    return (
        <>
            <table className="table table-striped table-hover dataTables-example">
                <thead>
                <tr>
                    {
                        Object.entries(table_header).map((value) => {
                            return (<th>{value[0]}</th>)
                        })
                    }
                </tr>
                </thead>
                <tbody>
                {
                    props.table_data.map((row, key) => {
                        return (
                            <tr>
                                {
                                    Object.entries(row).map((value, row_key) => {
                                        if (value[0].startsWith("is_")) {
                                            return (
                                                <td><i className={
                                                    value[1] == 1
                                                        ? "fa fa-check text-navy"
                                                        : "fa fa-close text-danger"}></i>
                                                </td>
                                            );
                                        } else if (value[0] === "rownum") {
                                            return (
                                                <button className="btn text-warning" data-toggle="modal"
                                                        onClick={() => editHandler(row)}><i
                                                    className="fa fa-pencil"
                                                    style={{display: "flex"}}></i></button>

                                            );
                                        } else if (value[0].startsWith("place_id")) {
                                            let url = "http://www.searchtoilet.com/admin/location_report/index.php?place_id=" + value[1];
                                            return (<td><a href={url} target="_blank">{value[1]}</a></td>);
                                        } else {
                                            return (
                                                <td>{value[1]}</td>
                                            );
                                        }
                                    })
                                }
                            </tr>
                        )
                    })
                }

                {/*
                    return (
                        <tr>
                            <td>{user.currentrow}</td>
                            <td>{user.username}</td>
                            <td>{user.emailid}</td>

                            <td>

                                <button className="btn text-warning" data-toggle="modal"
                                        onClick={() => editAdminHandler(user)}><i className="fa fa-pencil"
                                                                                  style={{display: "flex"}}></i>
                                </button>

                                <button className="btn text-warning" data-toggle="modal"
                                        onClick={() => editAdminHandler(user)}><i className="fa fa-trash"
                                                                                  style={{display: "flex"}}></i>
                                </button>

                            </td>
                        </tr>
                    )
                })
                     */
                }


                </tbody>
            </table>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Edit
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    {<Edit row={modalData}
                           db_table={props.db_table}
                           closepopup={handleCloseModal}/>}
                </Modal.Body>

            </Modal>
        </>
    )
}
export default Table;