import React, {useState} from "react";
import createGetFormAsyncErrors from "redux-form/lib/selectors/getFormAsyncErrors";

const AuthContext = React.createContext({

    token: '',
    isLoggedIn: false,
    isAdmin: false,
    login: (token) => {
    },
    logout: (token) => {
    },
    setAdmin: (value) => {
    },
});

export const AuthContextProvider = (props) => {
    const initialToken = localStorage.getItem('token');
    const [token, setToken] = useState(initialToken);
    const [isAdmin, setAdmin] = useState( JSON.parse(localStorage.getItem('is_admin')));
    const userIsLoggedIn = !!token;
    const userIsAdmin = isAdmin;

    const loginHandler = (token) => {
        setToken(token);
        localStorage.setItem('token', token);
    };

    const adminHandler = (isAdmin) => {
        setAdmin(isAdmin);
        localStorage.setItem('is_admin', isAdmin);
    };

    const logoutHandler = () => {
        setToken(null);
        localStorage.removeItem('token');

    };


    const context = {
        token: token,
        isLoggedIn: userIsLoggedIn,
        isAdmin: userIsAdmin,
        login: loginHandler,
        logout: logoutHandler,
        setAdmin: adminHandler
    };
    return (<AuthContext.Provider value={context}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContext;