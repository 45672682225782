import {Form, Button} from 'react-bootstrap'
import React, {useEffect, useState} from "react";
import {required, email, phoneNumber} from '../../forms/validate';
import axios from "../../axiosConfig.js";
import lodash from "lodash";
import InputBox from "./input_box";

const Edit = (props) => {
    let copy = lodash.cloneDeep(props.row);

    function onChangeHandler(key, value) {
        props.row[key] = value;
    }

    const updateHandler = (e) => {
        e.preventDefault();

        let updated_obj = {};
        Object.entries(copy).map((value, row_key) => {
            if (props.row[value[0]] !== value[1]) {
                updated_obj[value[0]] = props.row[value[0]];
            }
        });

        axios.post(`/common/update`, {
                id: props.row.id,
                table: props.db_table,
                records: updated_obj
            },
            {
                headers: {
                    accessToken: localStorage.token
                },
            },
        ).then((response) => {
            console.log(response.data);
            props.closepopup();
        });
    }
    return (
        <div>
            <form className="m-t" onSubmit={updateHandler}>

                {
                    Object.entries(props.row).map((value, row_key) => {
                        if (value[0] === 'rownum') {
                            return null;
                        }
                        return (
                            <div className="form-group  row">
                                <label className="col-sm-3 col-form-label">{value[0]}</label>
                                <div className="col-sm-9">
                                    <InputBox
                                        text={value[1]}
                                        name={value[0]}
                                        onChangeHandler={onChangeHandler}
                                    />
                                </div>
                            </div>
                        );
                    })
                }
                {
                    /*
                    <div className="form-group  row">
                        <label className="col-sm-3 col-form-label">Tester</label>

                        <div className="col-sm-9"><input type="checkbox" className="i-checks" checked={is_tester == 1}
                                                         onChange={(e) => setIsTester(e.target.checked)}/></div>
                    </div>

                     */
                }

                <button type="submit" className={"btn btn-sm btn-primary  full-width m-b"}>Update</button>
            </form>
        </div>
    )
}

export default Edit;