import React, {useState} from "react";
import Navigation from '../helpers/navigations'
import Header from '../helpers/header';
import {Progress} from 'reactstrap';
import {ToastContainer, toast} from 'react-toastify';
import axios from "../axiosConfig.js";

const FileUpload = () => {
    const [selectedfile, SetSelectedFile] = React.useState("");
    const [loaded, SetLoaded] = React.useState(0);


    // Handles file upload event and updates state
    const handleFileUpload = (event) => {
        console.log(event.target.files[0]);
        SetSelectedFile(event.target.files[0]);
    };

    const onClickHandler = () => {
        const data = new FormData()
        data.append('file', selectedfile);
        axios.post("/FileUpload/fileUpload", data, {})
            .then(res => {
                toast.success('upload success')
            })
            .catch(err => {
                toast.error('upload fail')
            })
    }
    return (
        <div id="wrapper" className="Dashboard_1">
            <Navigation/>
            <div id="page-wrapper" className="gray-bg">
                <Header/>
                <div className="wrapper wrapper-content">

                    <div className="row">

                        <div className="col-lg-12">
                            <div className="ibox ">
                                <div className="ibox-title">
                                    <h5>File Upload</h5>
                                    <div className="ibox-tools">
                                        <a className="collapse-link">
                                            <i className="fa fa-chevron-up"></i>
                                        </a>

                                    </div>
                                </div>
                                <div className="ibox-content">
                                    <div className="control-group">
                                        <div className="col-md-3">
                                            <input
                                                type="file"
                                                accept=".csv,.xlsx,.xls"
                                                onChange={handleFileUpload}
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <button type="button" className="btn btn-success btn-block"
                                                    onClick={onClickHandler}>Upload
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <ToastContainer/>
                                        {/* <Progress max="100" color="success" value={SetLoaded} >{Math.round(loaded,2) }%</Progress> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default FileUpload;