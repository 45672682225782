import React, {useState, useEffect} from 'react'
import {useHistory, BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Navigation from '../helpers/navigations'
import Header from '../helpers/header'
import axios from "../axiosConfig.js";

const STARating = () => {
    const [Duration, setDuration] = useState({filter: '2 Weeks'});
    const [fiveRating, setFiveRating] = useState(0);
    const [fourRating, setFourRating] = useState(0);
    const [threeRating, setThreeRating] = useState(0);
    const [twoRating, setTwoRating] = useState(0);
    const [oneRating, setOneRating] = useState(0);
    const [zeroRating, setZeroRating] = useState(0);
    const [totalRatingCount, setTotalRatingCount] = useState(0);

    useEffect(() => {
            staRatingHandler();
        },
        []);


    const staRatingHandler = (event) => {
        if (event != undefined) {
            Duration.filter = event.target.value;
        }

        axios.post(`/home/GetToiletRating`, {

                duration: Duration.filter,
            },
            {
                headers: {
                    accessToken: localStorage.token
                },
            },
        ).then((response) => {

            setFiveRating(response.data[0].TotalFiveStarRating);
            setFourRating(response.data[0].TotalFourStarRating);
            setThreeRating(response.data[0].TotalThreeStarRating);
            setTwoRating(response.data[0].TotalTwoStarRating);
            setOneRating(response.data[0].TotalOneStarRating);
            setZeroRating(response.data[0].TotalZeroStarRating);
            setTotalRatingCount(response.data[0].TotalRating);


        });
    }

    return (
        <div id="wrapper" className="Dashboard_1">
            <Navigation/>
            <div id="page-wrapper" className="gray-bg">
                <Header/>
                <div className="wrapper wrapper-content">

                    <div>
                        <div className="row">
                            <div className="col-sm-3 m-b-xs">
                                <div data-toggle="buttons" className="btn-group btn-group-toggle">
                                    <label className="btn btn-sm btn-white active"> <input type="radio" id="2Weeks"
                                                                                           value="2 Weeks"
                                                                                           name="options"
                                                                                           onClick={staRatingHandler}/> 2
                                        Weeks </label>
                                    <label className="btn btn-sm btn-white"> <input type="radio" id="3Months"
                                                                                    name="options" value="3 Months"
                                                                                    onClick={staRatingHandler}/> 3
                                        Months </label>
                                    <label className="btn btn-sm btn-white"> <input type="radio" id="6Months"
                                                                                    name="options" value="6 Months"
                                                                                    onClick={staRatingHandler}/> 6
                                        Months </label>
                                    <label className="btn btn-sm btn-white"> <input type="radio" id="Overall"
                                                                                    name="options" value="Overall"
                                                                                    onClick={staRatingHandler}/> Overall
                                    </label>
                                </div>

                            </div>
                            <div className="col-sm-9 m-b-xs pull-right">
                                <label className='font-bold'>Total Rating: </label>
                                <label className='font-bold'>{totalRatingCount}</label>
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-lg-4">
                                <div className="ibox ">
                                    <div className="ibox-title">
                                        <div className="ibox-tools">
                                            <span className="label label-primary float-right">{Duration.filter}</span>
                                        </div>
                                        <h5><i className="fa fa-star" style={{color: "orange"}}></i><i
                                            className="fa fa-star" style={{color: "orange"}}></i><i
                                            className="fa fa-star" style={{color: "orange"}}></i><i
                                            className="fa fa-star" style={{color: "orange"}}></i><i
                                            className="fa fa-star" style={{color: "orange"}}></i></h5>
                                    </div>
                                    <div className="ibox-content">
                                        <h1 className="no-margins">{fiveRating}</h1>


                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="ibox ">
                                    <div className="ibox-title">
                                        <div className="ibox-tools">
                                            <span className="label label-primary float-right">{Duration.filter}</span>
                                        </div>
                                        <h5><i className="fa fa-star" style={{color: "orange"}}></i><i
                                            className="fa fa-star" style={{color: "orange"}}></i><i
                                            className="fa fa-star" style={{color: "orange"}}></i><i
                                            className="fa fa-star" style={{color: "orange"}}></i></h5>
                                    </div>
                                    <div className="ibox-content">
                                        <h1 className="no-margins">{fourRating}</h1>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="ibox ">
                                    <div className="ibox-title">
                                        <div className="ibox-tools">
                                            <span className="label label-primary float-right">{Duration.filter}</span>
                                        </div>
                                        <h5><i className="fa fa-star" style={{color: "orange"}}></i><i
                                            className="fa fa-star" style={{color: "orange"}}></i><i
                                            className="fa fa-star" style={{color: "orange"}}></i></h5>
                                    </div>
                                    <div className="ibox-content">
                                        <h1 className="no-margins">{threeRating}</h1>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-lg-4">
                                <div className="ibox ">
                                    <div className="ibox-title">
                                        <div className="ibox-tools">
                                            <span className="label label-primary float-right">{Duration.filter}</span>
                                        </div>
                                        <h5><i className="fa fa-star" style={{color: "orange"}}></i><i
                                            className="fa fa-star" style={{color: "orange"}}></i></h5>
                                    </div>
                                    <div className="ibox-content">
                                        <h1 className="no-margins">{twoRating}</h1>


                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="ibox ">
                                    <div className="ibox-title">
                                        <div className="ibox-tools">
                                            <span className="label label-primary float-right">{Duration.filter}</span>
                                        </div>
                                        <h5><i className="fa fa-star" style={{color: "orange"}}></i></h5>
                                    </div>
                                    <div className="ibox-content">
                                        <h1 className="no-margins">{oneRating}</h1>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="ibox ">
                                    <div className="ibox-title">
                                        <div className="ibox-tools">
                                            <span className="label label-primary float-right">{Duration.filter}</span>
                                        </div>
                                        <h5><i className="fa fa-star" style={{color: "lightgray"}}></i><i
                                            className="fa fa-star" style={{color: "lightgray"}}></i><i
                                            className="fa fa-star" style={{color: "lightgray"}}></i><i
                                            className="fa fa-star" style={{color: "lightgray"}}></i><i
                                            className="fa fa-star" style={{color: "lightgray"}}></i></h5>
                                    </div>
                                    <div className="ibox-content">
                                        <h1 className="no-margins">{zeroRating}</h1>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default STARating;