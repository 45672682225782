import React from 'react';
import "../../src/App.css"
import {useContext} from 'react'
import AuthContext from "../../src/store/auth-context";

function Navigation() {
    const authCtx = useContext(AuthContext);
    return (<nav className="navbar-default navbar-static-side" role="navigation">
            <div className="sidebar-collapse">
                <ul className="nav metismenu" id="side-menu">
                    <li className="nav-header">
                        <div className="dropdown profile-element">
                            {/* <img alt="image" className="rounded-circle" src={profileIamge} /> */}
                            <a data-toggle="dropdown" className="dropdown-toggle" href="#">
                                <span className="block m-t-xs font-bold"></span>
                                <span className="text-muted text-xs block">Admin <b className="caret"></b></span>
                            </a>
                            <ul className={"dropdown-menu animated fadeInRight m-t-xs"}
                                style={{position: 'absolute', top: '70px', left: '0px', 'willChange': 'top, left'}}>
                                <li><a className="dropdown-item" href="#">Profile</a></li>
                                <li><a className="dropdown-item" href="#">Contacts</a></li>
                                <li><a className="dropdown-item" href="#">Mailbox</a></li>
                                <li className="dropdown-divider"></li>
                                <li><a className="dropdown-item" href="#">Logout</a></li>
                            </ul>
                        </div>

                    </li>

                    <li className="active">
                        <a href="#"><i className="fa fa-th-large"></i> <span className="nav-label"
                                                                             data-i18n="nav.dashboard">Pages</span>
                            <span className="fa arrow"></span></a>
                        <ul className="nav nav-second-level collapse in" aria-expanded="true">
                            <li><a href="/Home">dashboard</a></li>
                            <li className=""><a href="/Users">users</a></li>
                            <li className=""><a href="/UserLocationData">location_data_by_users</a></li>
                            <li className=""><a href="/AppError">app_error_data</a></li>
                            <li className=""><a href="/UserAlert">user_alerts</a></li>
                            <li className=""><a href="/UserClaim">user_claims</a></li>
                            <li className=""><a href="/UserVolunteerRequest">user_volunteer_requests</a></li>
                            {/*
                                <li className=""><a href="/STARating">ratings</a></li>
                                <li className=""><a href="/ToiletDataDetail">toilet_details</a></li>
                                <li className=""><a href="/SearchDataDetails">search_data</a></li>
                                <li className=""><a href="/ReportingQuestion">reporting_questions</a></li>

                            */}
                            <li className=""><a href="/Admin">admin</a></li>
                            {/*<li className=""><a href="/FileUpload">upload file</a></li>*/}
                            <li className=""><a href="http://www.searchtoilet.com/admin/file_manager/tinyfilemanager.php" target="_blank">UPLOAD CSV FILE</a></li>
                            <li className="">
                                <hr/>
                            </li>
                            {(authCtx.isAdmin) ?
                                <div>
                                    <li className=""><a href="/affiliates">affiliates</a></li>
                                    <li className=""><a href="/affiliates_invited_users">affiliates_invited_users</a>
                                    </li>
                                    <li className=""><a href="/features_list">features_list</a></li>
                                    <li className=""><a href="/filter_profile">filter_profile</a></li>
                                    <li className=""><a href="/reporting_questions">reporting_questions</a></li>
                                    <li className=""><a href="/restroom_attributes">restroom_attributes</a></li>
                                    <li className=""><a href="/restroom_in">restroom_in</a></li>
                                    <li className=""><a href="/restroom_types">restroom_types</a></li>
                                    <li className=""><a href="/review_questions">review_questions</a></li>
                                    <li className=""><a href="/sms_service">sms_service</a></li>
                                    <li className=""><a href="/sms_status">sms_status</a></li>
                                    <li className=""><a href="/user_clicks">user_clicks</a></li>
                                    <li className=""><a href="/user_network_contacts">user_network_contacts</a></li>
                                    <li className=""><a href="/user_reporting_feedback">user_reporting_feedback</a></li>
                                    <li className=""><a href="/user_review_feedback_v4">user_review_feedback_v4</a></li>
                                    <li className=""><a href="/user_rewards">user_rewards</a></li>
                                    <li className=""><a href="/user_search_log">user_search_log</a></li>
                                    <li className=""><a href="/user_shared_contacts">user_shared_contacts</a></li>
                                    <li className=""><a href="/user_volunteer_contacts">user_volunteer_contacts</a></li>
                                </div>
                                : null}
                        </ul>
                    </li>
                </ul>
            </div>
        </nav>
    )
};

export default Navigation;