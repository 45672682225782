import "./App.css";
import {useContext} from 'react'
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import Home from "./pages/Home";
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import AuthContext from "./store/auth-context";
import STARating from "./pages/STARating";
import FileUpload from "./pages/FileUpload"
import Common from "./pages/common/index"


function App() {
    const authCtx = useContext(AuthContext);
    console.log(authCtx);
    return (
        <div className="body">


            <Router>

                <Switch>
                    <Route path="/" exact component={Login}/>
                    <Route path="/Home">
                        {authCtx.isLoggedIn && <Home/>}
                        {!authCtx.isLoggedIn && <Redirect to='/'/>}
                    </Route>
                    <Route path="/STARating">
                        {authCtx.isLoggedIn && <STARating/>}
                        {!authCtx.isLoggedIn && <Redirect to='/'/>}

                    </Route>
                    <Route path="/Users">
                        {
                            authCtx.isLoggedIn &&
                            <Common
                                table={"users"}
                                dateColumn={"created_date"}
                            />
                        }
                        {!authCtx.isLoggedIn && <Redirect to='/'/>}
                    </Route>
                    <Route path="/SearchDataDetails">
                        {authCtx.isLoggedIn &&
                        <Common
                            table={"search_data"}
                            dateColumn={"updated"}
                        />
                        }

                        {!authCtx.isLoggedIn && <Redirect to='/'/>}
                    </Route>

                    <Route path="/Admin">
                        {authCtx.isLoggedIn &&
                        <Common
                            table={"admins"}
                            dateColumn={"createdAt"}
                        />
                        }
                        {!authCtx.isLoggedIn && <Redirect to='/'/>}
                    </Route>

                    <Route path="/AppError">
                        {authCtx.isLoggedIn &&
                        <Common
                            table={"app_error_data"}
                            dateColumn={"updated"}
                        />
                        }

                        {!authCtx.isLoggedIn && <Redirect to='/'/>}

                    </Route>

                    <Route path="/UserAlert">
                        {authCtx.isLoggedIn &&
                        <Common
                            table={"user_alerts"}
                            dateColumn={"updated"}
                        />
                        }

                        {!authCtx.isLoggedIn && <Redirect to='/'/>}

                    </Route>


                    <Route path="/UserClaim">
                        {authCtx.isLoggedIn &&
                        <Common
                            table={"user_claims"}
                            dateColumn={"updated"}
                        />
                        }

                        {!authCtx.isLoggedIn && <Redirect to='/'/>}

                    </Route>
                    <Route path="/UserVolunteerRequest">
                        {authCtx.isLoggedIn &&
                        <Common
                            table={"user_volunteer_requests"}
                            dateColumn={"updated"}
                        />
                        }

                        {!authCtx.isLoggedIn && <Redirect to='/'/>}

                    </Route>
                    <Route path="/ToiletDataDetail">
                        {authCtx.isLoggedIn &&
                        <Common
                            table={"toilet_data_details"}
                            dateColumn={"updated"}
                        />
                        }

                        {!authCtx.isLoggedIn && <Redirect to='/'/>}

                    </Route>

                    <Route path="/UserLocationData">
                        {authCtx.isLoggedIn &&
                        <Common
                            table={"location_data_by_users"}
                            dateColumn={"updated"}
                        />
                        }

                        {!authCtx.isLoggedIn && <Redirect to='/'/>}

                    </Route>

                    <Route path="/FileUpload">
                        {authCtx.isLoggedIn && <FileUpload/>}
                        {!authCtx.isLoggedIn && <Redirect to='/'/>}
                    </Route>
                    <Route path="/ReportingQuestion">
                        {authCtx.isLoggedIn &&
                        <Common
                            table={"reporting_questions"}
                            dateColumn={"updated"}
                        />
                        }
                        {!authCtx.isLoggedIn && <Redirect to='/'/>}
                    </Route>
                    <Route path="/affiliates"> {authCtx.isLoggedIn &&
                    <Common table={"affiliates"} dateColumn={"updated"}/>} {!authCtx.isLoggedIn &&
                    <Redirect to='/'/>} </Route>
                    <Route path="/affiliates_invited_users"> {authCtx.isLoggedIn &&
                    <Common table={"affiliates_invited_users"} dateColumn={"updated"}/>} {!authCtx.isLoggedIn &&
                    <Redirect to='/'/>} </Route>
                    <Route path="/features_list"> {authCtx.isLoggedIn &&
                    <Common table={"features_list"} dateColumn={"updated"}/>} {!authCtx.isLoggedIn &&
                    <Redirect to='/'/>} </Route>
                    <Route path="/filter_profile"> {authCtx.isLoggedIn &&
                    <Common table={"filter_profile"} dateColumn={"updated"}/>} {!authCtx.isLoggedIn &&
                    <Redirect to='/'/>} </Route>
                    <Route path="/reporting_questions"> {authCtx.isLoggedIn &&
                    <Common table={"reporting_questions"} dateColumn={"updated"}/>} {!authCtx.isLoggedIn &&
                    <Redirect to='/'/>} </Route>
                    <Route path="/restroom_attributes"> {authCtx.isLoggedIn &&
                    <Common table={"restroom_attributes"} dateColumn={"updated"}/>} {!authCtx.isLoggedIn &&
                    <Redirect to='/'/>} </Route>
                    <Route path="/restroom_in"> {authCtx.isLoggedIn &&
                    <Common table={"restroom_in"} dateColumn={"updated"}/>} {!authCtx.isLoggedIn &&
                    <Redirect to='/'/>} </Route>
                    <Route path="/restroom_types"> {authCtx.isLoggedIn &&
                    <Common table={"restroom_types"} dateColumn={"updated"}/>} {!authCtx.isLoggedIn &&
                    <Redirect to='/'/>} </Route>
                    <Route path="/review_questions"> {authCtx.isLoggedIn &&
                    <Common table={"review_questions"} dateColumn={"updated"}/>} {!authCtx.isLoggedIn &&
                    <Redirect to='/'/>} </Route>
                    <Route path="/sms_service"> {authCtx.isLoggedIn &&
                    <Common table={"sms_service"} dateColumn={"updated"}/>} {!authCtx.isLoggedIn &&
                    <Redirect to='/'/>} </Route>
                    <Route path="/sms_status"> {authCtx.isLoggedIn &&
                    <Common table={"sms_status"} dateColumn={"updated"}/>} {!authCtx.isLoggedIn &&
                    <Redirect to='/'/>} </Route>
                    <Route path="/user_clicks"> {authCtx.isLoggedIn &&
                    <Common table={"user_clicks"} dateColumn={"updated"}/>} {!authCtx.isLoggedIn &&
                    <Redirect to='/'/>} </Route>
                    <Route path="/user_network_contacts"> {authCtx.isLoggedIn &&
                    <Common table={"user_network_contacts"} dateColumn={"updated"}/>} {!authCtx.isLoggedIn &&
                    <Redirect to='/'/>} </Route>
                    <Route path="/user_reporting_feedback"> {authCtx.isLoggedIn &&
                    <Common table={"user_reporting_feedback"} dateColumn={"updated"}/>} {!authCtx.isLoggedIn &&
                    <Redirect to='/'/>} </Route>
                    <Route path="/user_review_feedback_v4"> {authCtx.isLoggedIn &&
                    <Common table={"user_review_feedback_v4"} dateColumn={"updated"}/>} {!authCtx.isLoggedIn &&
                    <Redirect to='/'/>} </Route>
                    <Route path="/user_rewards"> {authCtx.isLoggedIn &&
                    <Common table={"user_rewards"} dateColumn={"updated"}/>} {!authCtx.isLoggedIn &&
                    <Redirect to='/'/>} </Route>
                    <Route path="/user_search_log"> {authCtx.isLoggedIn &&
                    <Common table={"user_search_log"} dateColumn={"updated"}/>} {!authCtx.isLoggedIn &&
                    <Redirect to='/'/>} </Route>
                    <Route path="/user_shared_contacts"> {authCtx.isLoggedIn &&
                    <Common table={"user_shared_contacts"} dateColumn={"updated"}/>} {!authCtx.isLoggedIn &&
                    <Redirect to='/'/>} </Route>
                    <Route path="/user_volunteer_contacts"> {authCtx.isLoggedIn &&
                    <Common table={"user_volunteer_contacts"} dateColumn={"updated"}/>} {!authCtx.isLoggedIn &&
                    <Redirect to='/'/>} </Route>
                    <Route path="/registration" exact component={Registration}/>
                    <Route path="*">
                        <Redirect to='/'/>
                    </Route>

                    {
                        /*
                    <Route path="/Common">
                        {authCtx.isLoggedIn && <Common
                            table={"toilet_data_details"}
                            dateColumn={"updated"}
                        />}
                        {!authCtx.isLoggedIn && <Redirect to='/'/>}
                    </Route>
                         */
                    }
                </Switch>
            </Router>
        </div>

    );
}

export default App;
