import React, {useEffect, useRef, useState, CSSProperties} from "react";
import axios from "../../axiosConfig.js";
import Navigation from '../../helpers/navigations'
import Header from '../../helpers/header'
import Table from "../common/table";
import _ from "lodash";
import {CSVLink} from 'react-csv'
import {Button} from 'react-bootstrap'
import Loader from "react-spinners/FadeLoader";
import ProgressBar from "@ramonak/react-progress-bar";
import {useContext} from 'react'
import AuthContext from "../../store/auth-context";

//const pageSize = 250;

const Index = (props) => {
    const [records, setRecords] = useState([]);
    const [record_processed, setRecordProcessed] = useState(0);

    const [CSV_records, setCSVRecords] = useState([]);
    const [duration, setDuration] = useState({filter: 'all'});
    const [totalRecord, setTotalRecord] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchColumn, setSearchColumn] = useState([]);
    const [selectedSearchColumn, setSelectedSearchColumn] = useState('0');
    const [selectedSearchType, setSelectedSearchType] = useState('exact_match');
    let [loading, setLoading] = useState(false);

    const [searchText, SetSearchText] = useState('');
    const [pageSize, SetPageSize] = useState(10);
    const db_table = props.table ? props.table : 'users';
    const durationDateColumn = props.dateColumn ? props.dateColumn : '';
    const orderColumn = '';
    const csvLink = useRef();
    const authCtx = useContext(AuthContext);


    useEffect(() => {
            dataHandler();
        },
        []);

    const getDataInCsv = async () => {
        prePareCsvData();
    };

    const override: CSSProperties = {
        display: "block",
        margin: "0 auto",
    };

    const dataHandler = (event) => {
        let objectSearchConditions = {
            "columnName": selectedSearchColumn,
            "text": searchText,
            "searchType": selectedSearchType
        };
        let pageNumber = currentPage;
        //console.log(pageSize);
        if (event !== undefined) {
            if (event.target.localName === 'input') {
                //setDuration(event.target.value);
                duration.filter = event.target.value;
                pageNumber = 1;
                setCurrentPage(1);
            } else {
                pageNumber = parseInt(event.target.innerHTML);
                setCurrentPage(pageNumber);
            }
        }
        if (isNaN(currentPage)) {
            setCurrentPage(1);
        }

        if (isNaN(pageNumber)) {
            pageNumber = currentPage;
        }
        setLoading(true);
        axios.post(`/common/GetTableData`, {
                tableName: db_table,
                duration: duration.filter,
                objectSearchConditions: objectSearchConditions,
                pageNo: pageNumber,
                pageSize: pageSize,
                totalRecord: totalRecord,
                durationDateColumn: durationDateColumn,
                orderColumn: orderColumn
            },
            {
                headers: {
                    accessToken: localStorage.token
                },
            },
        ).then((response) => {
            if (response.data.length > 0) {
                setRecords(response.data);
                setTotalRecord(response.data[0]['rownum']);
                setSearchColumn(Object.keys(response.data[0]));
            } else {
                setRecords(response.data);
                setTotalRecord(0)
            }
            setLoading(false);
        });
    };

    function timeout(delay: number) {
        return new Promise(res => setTimeout(res, delay));
    }

    const processCSVData = async (csvHeaders) => {
        let error_at_row = "";
        let formatted_data = [];
        let cnt = 1;
        let percent = 0;
        let size = records.length;

        for (const key of Object.keys(records)) {
            percent = (cnt / size * 100);
            let data = [];
            // jsonData is parsed json object received from url
            csvHeaders.headers.forEach(key => {
                data[key] = "";
            });

            let final_data = {
                ...data,
                ...records[key]
            };
            //console.log(final_data.filter_data);

            let f_data = {};
            let number_of_toilets = {};
            let open_hours = {};
            try {

                let filter_data = flat(JSON.parse(JSON.parse(final_data.filter_data)));
                filter_data.forEach(e => {
                    f_data[e] = "Yes";
                });

                open_hours = JSON.parse(final_data.open_hours);
                Object.keys(open_hours).forEach(i => {
                    open_hours[i] = open_hours[i].join(",");
                });

                data = [];
                number_of_toilets = JSON.parse(JSON.parse(final_data.number_of_toilets));
                Object.keys(number_of_toilets).forEach(i => {
                    data[i.charAt(0).toUpperCase() + i.slice(1)] = number_of_toilets[i];
                });
                number_of_toilets = data;
            } catch (e) {
                error_at_row += ((cnt + 2) + ", ");
            }

            //console.log(number_of_toilets);
            //console.log(open_hours);

            delete final_data.city;
            delete final_data.country;
            delete final_data.state;
            delete final_data.icon;
            delete final_data.rownum;
            delete final_data.number_of_toilets;
            delete final_data.filter_data;
            delete final_data.open_hours;

            final_data = {
                ...final_data,
                ...f_data,
                ...number_of_toilets,
                ...open_hours,
            };
            formatted_data[key] = final_data;
            cnt++;
            if (percent % 2 === 0) {
                setRecordProcessed(percent);
                await timeout(100);
            }
        }

        setRecordProcessed(0);
        //console.log(records);
        //console.log(formatted_data);
        setCSVRecords(formatted_data);
        csvLink.current.link.click();

        if (error_at_row !== "") {
            alert("Please verify data for row(s)" + error_at_row);
        }
        console.error(error_at_row)

    };

    const prePareCsvData = async () => {


        let csvHeaders = [];
        fetch("https://q5lpv6czi8.execute-api.ap-south-1.amazonaws.com/v6/CsvHeaderValues")
            .then(response => response.json())
            .then((jsonData) => {
                processCSVData(jsonData);
            })
            .catch((error) => {
                console.error(error)
                alert("failed to convert data into CSV");
            })
    };

    function flat(a) {
        let b = [];
        if (a.constructor === Object) {
            Object.keys(a).forEach(function (k) {
                if (Array.isArray(a[k])) {
                    b = [...b, ...flat(a[k])];
                } else {
                    if (a[k].constructor === Object) {
                        b = [...b, ...flat(a[k])];
                    } else {
                        b.push(b[k])
                    }
                }
            });
            return b;
        }
        return a;
    }

    const submitReportPDF = () : void => {
        submitReportGeneration("pdf");
    }
    const submitReportEXCEL = () : void => {
        submitReportGeneration("excel");
        //alert("Not ready yet!");
    }
    function submitReportGeneration (type) {
        let file_name = ""
        if (type === 'pdf') {
            file_name = prompt('Save as? [PDF file-name]', "report.pdf");
            if (file_name && !file_name.includes(".pdf")) {
                file_name += ".pdf";
            }
        }
        if (type === 'excel') {
            file_name = prompt('Save as? [Excel file-name]', "report.xlsx");
            if (file_name && !file_name.includes(".xlsx")) {
                file_name += ".xlsx";
            }
        }
        let place_ids = records.map(x => x.place_id);

        let memo = prompt('Report memo?', "Report for ");
        let to_email = prompt('Email after completion?', localStorage.getItem('to_email'));
        if (file_name && to_email && memo) {
            localStorage.setItem('to_email', to_email);
            axios.post('/common/generate_report', {
                    place_ids: place_ids,
                    memo: memo,
                    file_name: file_name,
                    to_email: to_email
                },
                {
                    headers: {
                        accessToken: localStorage.token
                    },
                },
            ).then((response) => {
                if (response.data.length > 0) {
                    alert("Report will be ready soon!")
                } else {
                    alert("Report generation request failed :( ")
                }
            });
        } else {
            alert("File Name and recipient email address is required, \nPlease submit task request again.")
        }

    };
    const submitQRImages = () => {
        //console.log(records);
        let place_ids = records.map(x => x.place_id);
        //console.log(place_ids);
        let file_name = prompt('Save as? [ZIP file-name]', "");
        let to_email = prompt('Email after completion?', localStorage.getItem('to_email'));
        if (file_name && to_email) {
            localStorage.setItem('to_email', to_email);
            axios.post('/common/download_qr', {
                    place_ids: place_ids,
                    file_name: file_name,
                    to_email: to_email

                },
                {
                    headers: {
                        accessToken: localStorage.token
                    },
                },
            ).then((response) => {
                if (response.data.length > 0) {
                    alert("PDF file with Download QR will be ready soon!")
                } else {
                    alert("Request failed :( ")
                }
            });
        } else {
            alert("File Name and recipient email address is required, \nPlease submit task request again.")
        }
    };


    const pageCount = records ? Math.ceil(totalRecord / pageSize) : 0;

    const paginationHandler = (e) => {
        dataHandler(e);
    };

    const filterChangeHandler = (e) => {
        setSelectedSearchColumn(e.target.value);
    };

    const searchTypeChangeHandler = (e) => {
        setSelectedSearchType(e.target.value);
    };

    const searchChangeHandler = (searchText) => {
        SetSearchText(searchText);
    };

    const pageSizeChangeHandler = (event) => {
        let _pageSize = event.target.value;
        if (_pageSize === 0) {
            _pageSize = 1;
        }
        SetPageSize(_pageSize);
    };

    const pages = _.range(1, pageCount + 1);

    return (
        <div id="wrapper" className="Dashboard_1">
            <Navigation/>
            <div id="page-wrapper" className="gray-bg">
                <Header/>
                <div className="loading"
                     style={{display: (loading) ? 'block' : 'none'}}>
                    <Loader
                        color="#000000"
                        loading={loading}
                        cssOverride={override}
                        size={150}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
                <div className="wrapper wrapper-content">
                    {db_table === "location_data_by_users" ?
                        <div className="row">
                            {authCtx.isAdmin ?
                                <div className="col-sm-2">
                                    {record_processed == 0 ?
                                        <div className="input-group">
                                            <Button style={{backgroundColor: "gray"}} onClick={getDataInCsv}>Download
                                                data [csv]</Button>
                                            < CSVLink
                                                //enclosingCharacter={`||`}
                                                data={CSV_records}
                                                filename={db_table + '_' + currentPage + '_.csv'}
                                                className='hidden'
                                                ref={csvLink}
                                                target='_blank'
                                            />
                                        </div>
                                        :
                                        <ProgressBar completed={record_processed}/>
                                    }
                                </div>
                                : null
                            }
                            <div className="col-sm-2">
                                <div className="input-group">
                                    <Button style={{backgroundColor: "gray"}} onClick={submitReportPDF}>PDF
                                        Report</Button>
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <div className="input-group">
                                    <Button style={{backgroundColor: "gray"}} onClick={submitReportEXCEL}>Excel
                                        Report</Button>
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <div className="input-group">
                                    <Button style={{backgroundColor: "gray"}} onClick={submitQRImages}>QR Images Zip
                                        File</Button>
                                </div>
                            </div>
                            <div className="col-sm-4">
                            </div>
                        </div>
                        :
                        <div className="row">
                        </div>
                    }
                    <br/>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ibox">
                                <div className="ibox-title">
                                    <h2 className="text-center"><b style={{color: "red"}}>{db_table}</b></h2>
                                    <h3 className="text-center"><b style={{color: "purple"}}>[total records
                                        :{totalRecord}]</b></h3>
                                    <div className="ibox-tools">
                                        <a className="collapse-link">
                                            <i className="fa fa-chevron-up"/>
                                        </a>
                                    </div>
                                </div>
                                <div className="ibox-content">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="input-group">
                                                <input type="PageSize: min=1, max=1000" placeholder="PageSize" min={1}
                                                       max={1001}
                                                       className="form-control form-control"
                                                       onChange={pageSizeChangeHandler} value={pageSize}/>
                                                &nbsp;&nbsp;&nbsp;
                                                <button type="button" className="btn btn-primary"
                                                        onClick={dataHandler}> Set Page Size!
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="row">
                                        <div className="col-md-7">
                                            <div data-toggle="buttons" className="btn-group btn-group-toggle">
                                                <label className="btn btn-lg btn-white"> <input type="radio"
                                                                                                id="daily"
                                                                                                value="daily"
                                                                                                name="options"
                                                                                                onClick={dataHandler}/> Day
                                                </label>
                                                <label className="btn btn-lg btn-white"> <input type="radio"
                                                                                                id="weekly"
                                                                                                name="options"
                                                                                                value="weekly"
                                                                                                onClick={dataHandler}/> Week
                                                </label>
                                                <label className="btn btn-lg btn-white"> <input type="radio"
                                                                                                id="monthly"
                                                                                                name="options"
                                                                                                value="monthly"
                                                                                                onClick={dataHandler}/> Month
                                                </label>
                                                <label className="btn btn-lg btn-white active"> <input type="radio"
                                                                                                       id="all"
                                                                                                       name="options"
                                                                                                       value="all"
                                                                                                       onClick={dataHandler}/> All
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="input-group">
                                                <select className="form-control m-b-lg"
                                                        onChange={searchTypeChangeHandler}>
                                                    <option value="exact_match">Exact Match</option>
                                                    <option value="contains">Contains</option>
                                                </select>
                                                &nbsp;&nbsp;&nbsp;
                                                {searchColumn.length > 0 ? (
                                                    <select className="form-control m-b-lg"
                                                            onChange={filterChangeHandler}>
                                                        <option value="0">Filters</option>
                                                        {searchColumn.map((option) => (
                                                            <option value={option}>{option}</option>
                                                        ))}
                                                    </select>
                                                ) : ''}
                                                &nbsp;&nbsp;&nbsp;
                                                <input type="text" placeholder="Search"
                                                       className="form-control m-b-lg"
                                                       onChange={(e) => searchChangeHandler(e.target.value)}/>
                                                &nbsp;&nbsp;&nbsp;
                                                <span className="input-group-btn">
                                                    <button type="button" className="btn btn-mb btn-dark"
                                                            onClick={dataHandler}>Search</button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="table-responsive">
                                        <h3 className="text-left"><b style={{color: "purple"}}>[total records
                                            :{totalRecord}]</b></h3>

                                        <nav>
                                            <ul className="pagination">
                                                {
                                                    pages.map((page) => (
                                                        <li className={
                                                            page === currentPage ? "page-item active" : "page-item"
                                                        }>
                                                            <p className="page-link"
                                                               onClick={paginationHandler}
                                                            >{page}</p>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </nav>
                                        <div className="table-wrapper" style={{height: '600px'}}>
                                            {
                                                records.length > 0
                                                    ?
                                                    (
                                                        <Table
                                                            table_data={records}
                                                            refreshDataHandler={dataHandler}
                                                            db_table={db_table}
                                                        />
                                                    )
                                                    :
                                                    (<h5 className="text-center">No Record Found</h5>)
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default Index;
