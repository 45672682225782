import React, {useState} from "react";

import axios from "../axiosConfig.js";
import {Link} from 'react-router-dom';
import logo from '../assets/img/logo.png';
import '../assets/dependencies';
import LoginForm from "../forms/login";

function Login() {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const login = () => {
        const data = {userName: username, password: password};
        axios.post("/auth/login", data).then((response) => {

        });
    };
    return (
        <div className="gray-bg">
            <div className={"middle-box text-center loginscreen  animated fadeInDown"}>
                <div>
                    <div>
                        <img alt="" className="logo-app" src={logo}/>
                    </div>
                    <p>Login in. To see it in action.</p>
                    <LoginForm/>
                    <p className="m-t"><small> &copy; 2022</small></p>
                </div>
            </div>
        </div>
    );
}

export default Login;
